<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Tracks of a Killer - Week 1: Post-Lab Question 5</h2>

      <p class="mb-n3">a) Which variable does the experimenter manipulate and change?</p>

      <v-radio-group v-model="inputs.multChoice1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          class="pl-6"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>

      <p class="mb-n3">b) Which variable does the experimenter measure?</p>

      <v-radio-group v-model="inputs.multChoice2" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          class="pl-6"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>

      <p class="mb-n3">c) How do you construct a graph with independent and dependent variables?</p>

      <v-radio-group v-model="inputs.multChoice3" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          class="pl-6"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>

      <p>Use the data in the following table to answer the questions below:</p>

      <div class="mb-4 pl-6" style="position: relative; text-align: center">
        <v-simple-table style="width: 200px" dense>
          <thead>
            <th
              style="
                text-align: center;
                border-right: 1px solid #888;
                border-bottom: 1px solid #888;
              "
            >
              <stemble-latex content="$\text{x}$" />
            </th>
            <th style="text-align: center; border-bottom: 1px solid #888">
              <stemble-latex content="$\text{y}$" />
            </th>
          </thead>
          <tbody>
            <tr v-for="(yValue, i) in yValues" :key="yValue">
              <td style="text-align: center; border-right: 1px solid #888">
                {{ i }}
              </td>
              <td style="text-align: center">
                {{ yValue }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>

      <p>
        d) Given the following data, calculate the slope and y-intercept of the resulting graph.
      </p>

      <calculation-input
        v-model="inputs.slope"
        class="mb-4"
        prepend-text="$\text{slope:}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.yIntercept"
        class="mb-4"
        prepend-text="$\text{y-int:}$"
        :disabled="!allowEditing"
      />

      <p>e) In the above question, what is the equation of the line.</p>

      <v-text-field
        v-model="inputs.equation"
        class="mb-4"
        :disabled="!allowEditing"
        placeholder="y = mx + b"
        outlined
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import {defineComponent} from '@vue/composition-api';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default defineComponent({
  name: 'OleMissL9Part3',
  components: {
    NumberValue,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multChoice1: null,
        multChoice2: null,
        multChoice3: null,
        slope: null,
        yIntercept: null,
        equation: null,
      },
      options1: [
        {condition: 'Independent variable', value: '0'},
        {condition: 'Dependent variable', value: '1'},
      ],
      options2: [
        {condition: 'Independent on x-axis, dependent on y-axis ', value: '0'},
        {condition: 'Dependent on x-axis, independent on y-axis', value: '1'},
      ],
    };
  },
  computed: {
    givenSlope() {
      const slope = this.taskState.getValueBySymbol('givenSlope').content['number'];
      return parseInt(slope, 10);
    },
    givenYIntercept() {
      const yIntercept = this.taskState.getValueBySymbol('givenYIntercept').content['number'];
      return parseInt(yIntercept, 10);
    },
    yValues() {
      return Array.from({length: 5}, (_, i) => this.calculateY(i));
    },
  },
  methods: {
    calculateY(x) {
      x = parseInt(x, 10);
      const y = this.givenSlope * x + this.givenYIntercept;
      console.log(`Y value for ${x}: `, y);
      return y;
    },
  },
});
</script>
